<template>
  <div>
    <v-row>
      <v-col>
        <v-list three-line>
          <v-list-item to="/forms/dashboard">
            <v-icon class="mr-4" max-width="64" min-width="64"
              >mdi-form-select</v-icon
            >

            <v-list-item-content>
              <span class="text-uppercase font-weight-regular text-caption"
                >報告フォームを使った報告関連に使用します。</span
              >
              <div>フォーム関連</div>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title
              ><v-icon>mdi-information-outline</v-icon>お知らせ
            </v-list-item-title>
            <v-list-item-content>お知らせはありません。</v-list-item-content>
          </v-list-item-content>
        </v-list-item>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
  data: () => ({}),
};
</script>
